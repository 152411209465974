import * as React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";
import { useTheme } from "@mui/styles";
import Logo from "../components/Logo";
import HeroImage from "../images/Hero.webp";

import * as heroStyles from "../components/hero.module.css";

//<div className={heroStyles.container}>{children}</div>

const Header = ({ siteTitle, menuLinks }) => {
  const theme = useTheme();

  return (
    <header
      style={{
        background: `#1a2430`,
        marginBottom: `0.1rem`,
      }}
    >
      <div
        className={heroStyles.container}
        style={{
          backgroundImage: `url(${HeroImage})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center top",
        }}
      >
        <div
          style={{
            backgroundColor: "beige",
            paddingTop: "5px",
            paddingBottom: "5px",
            paddingLeft: "13px",
            paddingRight: "13px",
            opacity: "0.7",
            border: "2px double #1a2430",
            borderRadius: "2px",
            height: "50%",
          }}
        >
          <Link to="/">
            <Logo color="black" />
          </Link>
        </div>
      </div>

      <div>
        <nav>
          <ul
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: "5px",
            }}
          >
            {menuLinks.map((link) => (
              <li
                key={link.name}
                style={{
                  listStyleType: `none`,
                  padding: `1rem`,
                  textAlign: "center",
                  lineHeight: "0.8rem",
                }}
              >
                <Link
                  style={{
                    color: "beige",
                    //fontFamily: "Montserrat, sans-serif",
                    fontFamily: "Bodoni+Moda, sans-serif",
                    fontWeight: 300,
                    fontSize: "0.9rem",
                  }}
                  activeStyle={{
                    fontSize: "1.3rem",
                    fontWeight: 500,
                    lineHeight: "1.2rem",
                    // borderBottom: "1px dotted beige",
                  }}
                  to={link.link}
                >
                  <span>{link.name}</span>
                </Link>
              </li>
            ))}
          </ul>
        </nav>
      </div>
    </header>
  );
};

Header.propTypes = {
  siteTitle: PropTypes.string,
};

Header.defaultProps = {
  siteTitle: ``,
};

export default Header;
