import * as React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";
import { useTheme } from "@mui/styles";
import { SocialIcon } from "react-social-icons";
//<div className={heroStyles.container}>{children}</div>

const Footer = () => {
  const theme = useTheme();

  return (
    <div
      style={{
        backgroundColor: "#1a2430",
        height: "50px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <SocialIcon
        bgColor="beige"
        fgColor="#1a2430"
        network="email"
        url="mailto:lefafa.bag@gmail.com"
        style={{ height: 30, width: 30, margin: 10 }}
      />
      <SocialIcon
        bgColor="beige"
        fgColor="#1a2430"
        network="instagram"
        url="https://www.instagram.com/lefafa.bag/"
        style={{ height: 30, width: 30, margin: 10 }}
      />
    </div>
  );
};

export default Footer;
