/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react";
import PropTypes from "prop-types";
import { useStaticQuery, graphql } from "gatsby";
import { createTheme, ThemeProvider } from "@mui/material/styles";

import Header from "./Header";
import Footer from "./Footer";

const Layout = ({ children }) => {
  const theme = createTheme({
    palette: {
      primary: {
        main: "#135634",
        secondary: "#1A543C",
      },
    },
  });

  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
          menuLinks {
            name
            link
          }
        }
      }
    }
  `);

  return (
    <div
      style={{ display: "flex", minHeight: "100vh", flexDirection: "column" }}
    >
      <ThemeProvider theme={theme}>
        <Header
          menuLinks={data.site.siteMetadata.menuLinks}
          siteTitle={data.site.siteMetadata?.title || `Title`}
        />
        <div
          style={{
            margin: `0 auto`,
            maxWidth: 960,
            padding: `0 0.5rem 1.45rem`,
            flexGrow: 1,
          }}
        >
          <div>{children}</div>
        </div>
        <Footer />
      </ThemeProvider>
    </div>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
